@charset "utf-8";

$emph-color: #800;

html {
  overflow-y: scroll;
}

body {
  padding-top: 70px;
}

code.tex {
  background-color: #f5f5f5;
}

article > h1.page-header {
  color: $emph-color;
  margin-top: 0;
}

#footer {
  margin-top: 1em;
}

#lt-twitter-btn {
  /* Differenz ist #D0D0C */
  background-color: #4099FF;
  border-color: #338CF3;
}

#lt-gplus-btn {
  /* Differenz ist #D0D0C */
  background-color: #d34836;
  border-color: #c63b2a;
}

#lt-toc > li > a {
  color: #222;
  padding: 0;
}

.lt-toc-sub a {
  color: #777;
  margin-left: 1em;
  padding: 2px 0;
}

.lt-toc-sub a:first-child {
  padding: 4px 0 2px 0;
}

#lt-toc > li.active > a {
  color: $emph-color;
  font-weight: 700;
}

.lt-toc-sub li.active {
  border-left: 2px solid $emph-color;
}

aside .addthis_toolbox {
  margin-top: 1em;
}
